import { inject }           from 'aurelia-framework';
import { BaseRepository }   from 'modules/core/services/base-repository';
import { CustomHttpClient } from 'resources/services/custom-http-client';

@inject(CustomHttpClient)
export class InterventionTypesRepository extends BaseRepository {
    baseUrl = 'management/concessions/concessions/intervention-types';

    /**
     * Creates a record
     *
     * @param data
     *
     * @param highwayId
     * @returns {*}
     */
    create(data, highwayId) {
        return this.httpClient.post(`management/concessions/concessions/${highwayId}/intervention-types`, data);
    }

    /**
     * Returns all lot interventions for a given intervention type
     *
     * @param interventionTypeId
     * @param criteria
     * @return {*}
     */
    lotInterventions(interventionTypeId, criteria) {
        return this.httpClient.post(`${this.baseUrl}/${interventionTypeId}/lot-interventions`, criteria);
    }

    /**
     * Returns next intervention number
     *
     * @param interventionTypeId
     * @param data
     * @return {*}
     */
    nextInterventionNumber(interventionTypeId, data) {
        return this.httpClient.post(`${this.baseUrl}/${interventionTypeId}/next-intervention-number`, data);
    }
}
